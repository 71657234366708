import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import { importComponent } from 'common/loaders/importComponent';
import GlifosMetadataLoading from 'modules/glifos-rich-media/components/GLifosMetadata/GlifosMetadataLoading/GlifosMetadataLoading';
import NewsnEvents from 'modules/website/components/NewsnEvents/NewsnEvents';
import SideBar from 'modules/website/components/SideBars/SideBar/SideBar';
import WatchNext from 'modules/website/components/SideBars/WatchNext/WatchNext';
/* import SideBarDocument from 'modules/glifos-rich-media/components/SideBarDocument/SideBarDocument';
import SideBarVideo from 'modules/glifos-rich-media/components/SideBarVideo/SideBarVideo'; */
import { useAliasNodePage } from 'modules/website/hooks/useAliasNodePage';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ComponentMap } from 'routes/ComponentMap';
import ReactGA from 'react-ga4';

//const RichMediaView = lazy(() => import('../GlifosRichMediaView/GlifosRichMediaView'));
//const InfromationView = lazy(() => import('../InfromationView/InfromationView'));

export default function AliasNodeView() {
	const location = useLocation();
	/* const [ViewLoad, setViewLoad] = useState(null); */
	const [leftBar, setLeftBar] = useState(null);
	const [rightBar, setRightBar] = useState(null);
	const [title, setTitle] = useState(null);
   /*  const [breadC, setBreadC] = useState(null);
    const [description, setDescription] = useState(null); */
	const [component, setComponent] = useState(null);
	const alias = location.pathname.replace('/', '');
	const [aliasNode, setAliasNode] = useState(null);
	const { isLoading, isError, payload, error } = useAliasNodePage({
		alias: alias,
	});

	console.log(payload);
	useEffect(() => {
		setAliasNode(alias);
		return () => {
			setAliasNode(null);
			setRightBar(null);
			setComponent(null);
		};
	}, [alias]);

	/* useEffect(() => {
		if (!isLoading && payload) {
			ReactGA.pageview(location.pathname + location.search);
		}
	}, [isLoading, payload, location.pathname, location.search]); */

	//console.log(error);

	useEffect(() => {
		if (!isLoading && payload) {
			const type = payload.type[0].target_id;
			//console.log(type);
			switch (type) {
				case 'rich_media_archive':
					//get from ComponentMap where type is the same
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					setRightBar(
						<SideBar>
							<WatchNext quantity={9} alias={aliasNode} />
						</SideBar>
					);
					setTitle(payload.title[0].value);
					break;
				case 'information':
					setRightBar(
						<SideBar>
							<WatchNext btnMore quantity={3} />
							<NewsnEvents btnMore={true} />
						</SideBar>
					);
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					break;
				case 'general_page':
					setRightBar(
						<SideBar>
							<WatchNext btnMore quantity={3} />
							<NewsnEvents btnMore={true} />
						</SideBar>
					);
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					break;
				case 'wide_general_page':
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					break;
				default:
					// Manejo de tipo desconocido o sin asignar
					break;
			}
		}
	}, [payload]);

	const ViewComponent = component ? importComponent(component.c) : null;

	return component && component.l === 'right' ? (
		<SideBarLayout leftBar={leftBar} rightBar={rightBar}>
			<Suspense fallback={<GlifosMetadataLoading />}>
				{' '}
				<ViewComponent title={title}/>
			</Suspense>
		</SideBarLayout>
	) : component && component.l === 'central' ? (
		<BasicLayout>
			<Suspense fallback={<div>Loading...</div>}>
				{' '}
				<ViewComponent title={title}/>
			</Suspense>
		</BasicLayout>
	) : (
		<></>
	);
}
