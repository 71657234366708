import AccordionItext from "modules/glifos-rich-media/components/AccordionItext/AccordionItext";
import CreateSegment from "modules/glifos-rich-media/components/CreateSegment/CreateSegment";
import GLifosMetadata from "modules/glifos-rich-media/components/GLifosMetadata/GLifosMetadata";
import GlifosMetadataLoading from "modules/glifos-rich-media/components/GLifosMetadata/GlifosMetadataLoading/GlifosMetadataLoading";
import GlifosRichMediaPlayerIOS_test from "modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaIOS_test";
//import GlifosRichMediaPlayer from "modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaPlayer_test";
import GlifosRichMediaPlayer from "modules/glifos-rich-media/components/GlifosRichMediaPlayer/GlifosRichMediaPlayer";
import GlifosRichMediaProvider from "modules/glifos-rich-media/components/GlifosRichMediaProvider/GlifosRichMediaProvider";
import Alert from "modules/website/components/Alert/Alert";
import { useVideoInfoPage } from "modules/website/hooks/useVideoInfoPage";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

export default function GlifosRichMediaView(props) {
    const { title } = props;
    const location = useLocation();
    //const alias = location.pathname.replace('/', '');
    let { alias, nid } = useParams();
    //console.log('alias', alias);
    ///console.log('nid', nid);
    const { isLoading, isError, payload, error } = useVideoInfoPage({
        alias: alias?alias:nid,
        stylesheet: 'DefaultJSON',
    });
    /* const playerData = useGlifosPlayerData();
    const playerActions = useGlifosPlayerUpdate();

    useEffect(() => {
        if (payload) {
            console.log('playerData', playerData);
            if(playerData?.playing){
                playerActions.commandToPlayer({ type: "pause" });
            }
        }
    }, [payload]); */

    //TODO: bug de las keywords https://dev.texasarchive.local/2017_00473
    //TODO: ver despues si se deja como highlights
    console.log(payload);
    return <GlifosRichMediaProvider>
        <Container className='py-5'>
            <Row>
                {isLoading && <GlifosMetadataLoading />}
                {isError && <Alert variant="danger">{error}</Alert>}
                <Col md={12}>
                    {!isLoading && !isError && payload && (
                        <>
                            <GlifosRichMediaPlayerIOS_test src={payload?.metadata?.video?.src} thumbnail={payload?.metadata?.video?.thumbnail} />
                            <CreateSegment title={payload?.metadata?.title}/>
                        </>
                    )}
                </Col>

                {!isLoading && !isError && payload && (
                    <>
                        <Col md={12}>
                            <GLifosMetadata
                                metadata={payload?.metadata}
                                itext={payload?.itext}
                                extra={payload?.extra}
                                comments={payload?.comments}
                                nid={payload?.nid}
                            />
                        </Col>
                    </>
                )}
            </Row>
            {props.children}
        </Container>
    </GlifosRichMediaProvider>
}