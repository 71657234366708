import React, { useEffect } from 'react';
import styles from './VideosView.module.scss';
import SideBarBtn from 'modules/website/components/SideBars/SideBarBtn/SideBarBtn';
import VideosViewLoading from './VideosViewLoading/VideosViewLoading';
import { Col, Row } from 'react-bootstrap';
import ContentCard from 'modules/website/components/ContentCard/ContentCard';
import ApiURLs from 'services/BackendApi/BackendApiURLs';
import {
	useWatchNextPage,
	useWatchNextRandomVideo,
} from 'modules/website/hooks/useWatchNextPage';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
export default function VideosView() {
	const { isLoading, isError, payload, error } = useWatchNextPage({
		quantity: 24,
		//sortby: 'random_' + Math.floor(Date.now() / 1000).toString(),
		sortby: 'glifosid',
		orderby: 'desc',
	});
	const {
		isLoading: isLoading2,
		isError: isError2,
		payload: payload2,
		error: error2,
	} = useWatchNextRandomVideo();
	const navigate = useNavigate();
	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	return (
		<div>
			<div className={`${styles.title} py-5`}>Featured Videos</div>
			<div>
				{isLoading && <VideosViewLoading />}
				{isError && <div>{error}</div>}
				<Row>
					{payload &&
						payload.records.record.map((item, index) => (
							<Col md={12} lg={6} key={index}>
								<ContentCard
									key={index}
									title={item.title}
									image={ApiURLs.baseURL + item.thumbnail}
									description={item.blurb}
									nav={item.id}
								/>
							</Col>
						))}
				</Row>
			</div>
			<div className={`${styles.btn}`}>
				<SideBarBtn
					text='Random Video'
					func={() => navigate(payload2[0].alias)}
				/>
			</div>
		</div>
	);
}
